<template>
    <div class="mt-4 bg-white p-8">
        <h4>{{ $t('admin.challenges.settings.general-info.title') }}</h4>

        <div class="flex ml-0 flex-column w-full md:w-1/2 lg:w-1/3 pb-2">
            <vs-input class="border-grey-light w-full" type="text" v-model="challenge.title"
                :label="$t('admin.challenges.settings.title')" required />

            <vs-checkbox v-model="challenge.is_draft" class="my-5 checkboxform border-0 mx-0">
                {{ this.$t('admin.challenges.settings.draft') }}
            </vs-checkbox>

            <div class="flex flex-row gap-4">
                <DatePicker :label="`${$t('admin.challenges.settings.startDate')}`" v-model="startDateObject" />
                <DatePicker 
                    :disabled="challenge.start_date === ''" 
                    :label="$t('admin.challenges.settings.endDate')" 
                    v-model="endDateObject" 
                    :minDate="dateFromSQL(challenge.start_date).plus({days: 1})"
                />
            </div>

            <vs-select class="border-grey-light pb-2" v-model="challenge.timeofyear" required
                :label="$t('timeofyear')">
                <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                    v-for="option in timeofyearOptions" />
            </vs-select>

        </div>

        <h4 class="mt-5">{{ $t('admin.challenges.settings.settings.title') }}</h4>

        <div class="flex ml-0 flex-column w-full pb-2">
            <vs-checkbox v-model="challenge.automatic_subscription" class="md:w-1/3 my-5 checkboxform border-0 mx-0">
                {{ this.$t('admin.challenges.settings.automatic_subscription') }}
            </vs-checkbox>

            <vs-select class="border-grey-light pb-2 w-full md:w-1/3" v-model="challenge.type_id" required
                @change="typeChanged"
                :label="$t('admin.challenges.settings.type_title')">
                <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                    v-for="option in challegeTypes" />
            </vs-select>

            <div>
                <vs-select
                    v-if="challenge.type_id == 1 || challenge.type_id == 2"
                    class="border-grey-light pb-2 w-full md:w-1/3" v-model="challenge.statistic_type_id"
                    :label="$t('admin.challenges.settings.statistic')"
                >
                    <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                        v-for="option in challengeStatisticTypes" />
                </vs-select>

                <vs-input 
                    v-if="challenge.type_id == 1 || challenge.type_id == 2"
                    class="border-grey-light w-full md:w-1/3 " type="number" min="1" v-model="challenge.threshold"
                    :label="$t('admin.challenges.settings.threshold')" />
                
                <vs-input 
                    v-if="challenge.type_id == 2"
                    class="border-grey-light w-full md:w-1/3 " type="number" min="1" v-model="challenge.milestone"
                    :label="$t('admin.challenges.settings.milestone')" />

                <vs-select
                    v-if="challenge.type_id == 1 || challenge.type_id == 2"
                    class="border-grey-light pb-2 w-full md:w-1/3" v-model="challenge.units" 
                    :label="$t('admin.challenges.settings.units_title')"
                >
                    <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                        v-for="option in unitTypes" />
                </vs-select>

                <Combobox
                    v-if="challenge.type_id == 6"
                    class="mt-4 md:w-1/3"
                    :options="allChallenges.map(c => ({ key: c.uuid, text: c.title }))"
                    :label="$t('admin.challenges.settings.copy_challenge')"
                    @input="challengeSelectedForCustomType"
                />

                <label v-if="challenge.type_id == 6" class="vs-input--label">{{ $t('admin.challenges.settings.query') }}</label>
                <div v-if="challenge.type_id == 6" class="vs-con-input">
                    <textarea class="w-full h-32 rounded resize-none border-grey-light p-2"
                        v-model="challenge.query" 
                    />
                </div>
            </div>
            <div>
                <Combobox
                    v-model="challenge.activity_id"
                    class="my-4"
                    :multiple="true"
                    :options="activities.map(a => ({ key: a.id, text: `${a.name} - ${a.timeofyear}` }))"
                    :label="$t('admin.challenges.settings.activities')"
                />
                <div class="flex flex-row gap-2 flex-wrap">
                    <button class="sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg w-1/3" @click.stop="addActivities('winter')">
                        {{ $t('admin.challenges.settings.add_winter_activities') }}
                    </button>
                    <button class="sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg w-1/3" @click.stop="addActivities('summer')">
                        {{ $t('admin.challenges.settings.add_summer_activities') }}
                    </button>
                    <button class="sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg w-1/3" @click.stop="addActivities('always')">
                        {{ $t('admin.challenges.settings.add_always_activities') }}
                    </button>
                    <button class="sk-btn sk-btn-small btn-border sk-btn-danger rounded-lg" @click.stop="challenge.activity_id = []">
                        <feather-icon icon="TrashIcon" class="align-middle" svgClasses="text-danger w-4" />
                    </button>
                </div>

                <Combobox
                    v-model="challenge.resort_ids"
                    class="my-4"
                    :multiple="true"
                    :options="resorts.map(r => ({ key: r.id, text: r.name }))"
                    :asyncFirstFetchCallback="fetchNewResorts"
                    :asyncNextFetchCallback="fetchNextResorts"
                    :label="$t('admin.challenges.settings.resorts')"
                />

                <vs-select :label="$t('admin.challenges.settings.timezone')" v-model="challenge.timezone" class="w-full md:w-1/3">
                    <vs-select-item v-for="(item, index) in timezones" :key="index" :value="item" :text="item"  />
                </vs-select>
            </div>
        </div>

        <h4 class="mt-5">{{ $t('admin.challenges.settings.visibility.title') }}</h4>

        <div class="flex ml-0 flex-column w-full pb-2">
            <vs-checkbox v-model="challenge.show_unclassified" class="md:w-1/3 my-5 checkboxform border-0 mx-0">
                {{ this.$t('admin.challenges.settings.show_unclassified') }}
            </vs-checkbox>

            <div class="flex flex-row gap-4">
                <DatePicker :label="`${$t('admin.challenges.settings.publish_start_date')}`" v-model="publishStartDateObject" />
                <DatePicker 
                    :disabled="challenge.publish_start_date === ''" 
                    :label="$t('admin.challenges.settings.publish_end_date')" 
                    v-model="publishEndDateObject" 
                    :minDate="dateFromSQL(challenge.publish_start_date).plus({days: 1})"
                />
            </div>

            <vs-select :label="$t('admin.challenges.settings.community')" v-model="challenge.community_uuid" class="w-full md:w-1/3">
                <vs-select-item v-for="(item, index) in communities" :key="index" :value="item.uuid" :text="item.name"  />
            </vs-select>

            <Combobox
                v-model="challenge.application_ids"
                class="my-4"
                :multiple="true"
                :options="apps.map(r => ({ key: parseInt(r.id), text: r.name }))"
                :asyncFirstFetchCallback="fetchNewApplications"
                :asyncNextFetchCallback="fetchNextApplications"
                :label="$t('admin.challenges.settings.applications')"
            />
        </div>

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button class="rounded-lg xs:w-full sm:w-auto ml-2 " @click="save">
                {{ $t('resorts.touristic-objects.form.saveButton') }}
            </vs-button>
            <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary" @click="$router.push({name: 'admin-challenges'})">
                {{ $t('resorts.touristic-objects.form.cancelButton') }}
            </vs-button>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import store from "@/modules/Shared/Store/store";
import loader from "@/modules/Shared/Mixins/loader";
import ChallengeService from '../Services/ChallengeService';
import DatePicker from '@/modules/Shared/Components/form/DatePicker.vue';
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import TrackService from "@/modules/Apps/Services/TrackService";
import ResortService from "@/modules/Apps/Services/ResortService";
import timezones from '@/timezones.json'
import CommunityService from "@/modules/Admin/Services/CommunityService";
import AppsService from "@/modules/Apps/Services/AppsService";

export default {
    name: "ChallengeSettingsPage",
    components: { DatePicker, Combobox },
    mixins: [loader],
    data() {
        return {
            new: false,
            challenge: {
                main_language_id: 1,
                title: '',
                is_draft: true,
                start_date: '',
                end_date: '',
                publish_start_date: '',
                publish_end_date: '',
                timeofyear: 'winter',
                automatic_subscription: false,
                type_id: 1,
                statistic_type_id: null,
                threshold: null,
                milestone: null,
                units: null,
                query: null,
                show_unclassified: false,
                activity_id: [],
                resort_ids: [],
                application_ids: [],
                timezone: 'Europe/Madrid',
                community_uuid: null,
            },
            allChallenges: [],
            unitTypes: [
                { key: 'day', label: this.$t('admin.challenges.settings.units.days') },
                { key: 'challenge', label: this.$t('admin.challenges.settings.units.challenge') },
            ],
            timeofyearOptions: [
                { key: 'winter', label: this.$t('winter') },
                { key: 'summer', label: this.$t('summer') },
                { key: 'always', label: this.$t('always') },
            ],
            challegeTypes: [
                { key: 1, label: this.$t('admin.challenges.settings.type.max_stat') },
                { key: 2, label: this.$t('admin.challenges.settings.type.milestone') },
                { key: 4, label: this.$t('admin.challenges.settings.type.geophoto') },
                { key: 5, label: this.$t('admin.challenges.settings.type.no_activity') },
                { key: 6, label: this.$t('admin.challenges.settings.type.custom') },
                { key: 7, label: this.$t('admin.challenges.settings.type.gimcana') },
            ],
            challengeStatisticTypes: [
                { key: 1, label: this.$t('admin.challenges.settings.statistic_type.distance') },
                { key: 2, label: this.$t('admin.challenges.settings.statistic_type.drop') },
                { key: 3, label: this.$t('admin.challenges.settings.statistic_type.days') },
                { key: 4, label: this.$t('admin.challenges.settings.statistic_type.slope_distance') },
                { key: 5, label: this.$t('admin.challenges.settings.statistic_type.duration') },
            ],
            activities: [],
            resorts: [],
            apps: [],
            timezones: [],
            communities: [],
            checkedApps: [],
            comboboxPage: 0,
            comboboxEnded: false,
            resortComboboxPage: 0,
            resortComboboxEnded: false,
        }
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const challenge = await ChallengeService.getChallenge(to.params.uuid)
            await store.dispatch('setRouteElement', challenge)
            next(vm => vm.challenge = JSON.parse(JSON.stringify(challenge)))
        } catch (e) {
            await store.dispatch('setRouteElement', null)
            next(vm => vm.new = true)
        }
    },
    computed: {
        ...mapGetters(['spotlioLanguages']),
        startDateObject: {
            get() { return this.challenge.start_date != '' ? this.dateFromSQL(this.challenge.start_date) : null },
            set(val) { this.challenge.start_date = val.toFormat('yyyy-MM-dd HH:mm:ss') }
        },
        endDateObject: {
            get() { return this.challenge.end_date != '' ? this.dateFromSQL(this.challenge.end_date) : null },
            set(val) { this.challenge.end_date = val.endOf('day').toFormat('yyyy-MM-dd HH:mm:ss') }
        },
        publishStartDateObject: {
            get() { return this.challenge.publish_start_date && this.challenge.publish_start_date != '' ? this.dateFromSQL(this.challenge.publish_start_date) : null },
            set(val) { this.challenge.publish_start_date = val.toFormat('yyyy-MM-dd HH:mm:ss') }
        },
        publishEndDateObject: {
            get() { return this.challenge.publish_end_date && this.challenge.publish_end_date != '' ? this.dateFromSQL(this.challenge.publish_end_date) : null },
            set(val) { this.challenge.publish_end_date = val.endOf('day').toFormat('yyyy-MM-dd HH:mm:ss') }
        },
    },
    async created() {
        this.timezones = await timezones
        await this.loadAndNotify(async () => {
            [this.activities, this.resorts, this.communities, this.apps ]  = await Promise.all([
                unifyPaginatedResponse(TrackService.getTrackActivities),
                unifyPaginatedResponse(ResortService.getResorts, PaginationService.parseParameters({
                    filters: [ { field: 'id', value: this.challenge.resort_ids.join(';') } ],
                    per_page: 30,
                })),
                unifyPaginatedResponse(CommunityService.getCommunities),
                unifyPaginatedResponse(AppsService.getApps, PaginationService.parseParameters({
                    filters: [ { field: 'id', value: this.challenge.application_ids.join(';') } ],
                    per_page: 30,
                })),
            ])
        }, true)
    },
    methods: {
        dateFromSQL(date) {
            return DateTime.fromSQL(date)
        },
        async typeChanged(type) {
            if (type === 6) {
                await this.loadAndNotify(async () => {
                    this.allChallenges = await unifyPaginatedResponse(ChallengeService.getChallenges, PaginationService.parseParameters({}))
                }, true)
            }
        },
        async challengeSelectedForCustomType(challengeUuid) {
            await this.loadAndNotify(async () => {
                const originChallenge = ChallengeService.getChallenge(challengeUuid)
                this.challenge.query = originChallenge.query
            }, true)
        },
        addActivities(timeofyear) {
            let newIds = this.activities.filter(a => a.timeofyear === timeofyear).map(a => a.id)
            this.challenge.activity_id = [...new Set([...this.challenge.activity_id, ...newIds])];
        },
        async save() {
            await this.loadAndNotify(async () => {
                if (this.new) {
                    await ChallengeService.createChallenge(this.$route.params.uuid, this.challenge)
                    return
                }
                await ChallengeService.saveChallenge(this.$route.params.uuid, this.challenge)
            })
            this.$router.push({ name: 'admin-challenges' })
        },
        async fetchApplications(filter, page = 1) {
            const response = await AppsService.getApps(
                filter ? PaginationService.parseParameters({
                    filters: [ { field: 'name', value: filter } ],
                    per_page: 30,
                }) : {per_page: 30}, 
                page
            )

            if (response.data.length < 30) {
                this.comboboxEnded = true
            }

            this.apps = [...this.apps, ...response.data]
        },
        fetchNextApplications(filter) {
            if (this.comboboxEnded) {
                return
            }
            this.comboboxPage += 1
            return this.fetchApplications(filter, this.comboboxPage)
        },
        fetchNewApplications(filter) {
            this.apps = []
            this.comboboxPage = 1
            return this.fetchApplications(filter, this.comboboxPage)
        },
        async fetchResorts(filter, page = 1) {
            const response = await ResortService.getResorts(
                filter ? PaginationService.parseParameters({
                    filters: [ { field: 'name', value: filter } ],
                    per_page: 30,
                }) : {per_page: 30}, 
                page
            )

            if (response.data.length < 30) {
                this.resortComboboxEnded = true
            }

            this.resorts = [...this.resorts, ...response.data]
        },
        fetchNextResorts(filter) {
            if (this.resortComboboxEnded) {
                return
            }
            this.resortComboboxPage += 1
            return this.fetchResorts(filter, this.resortComboboxPage)
        },
        fetchNewResorts(filter) {
            this.resorts = []
            this.resortComboboxPage = 1
            return this.fetchResorts(filter, this.resortComboboxPage)
        },
    }
};
</script>