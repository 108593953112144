import axios from '@/axios.js'

export default {
    getCommunities: async (page = 1) => {
        let response = await axios.adminapi.get(`v4/communities`,{
            params: {
                page: page,
            }
        })
        return response.data.object
    },
}
